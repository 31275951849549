// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-fotogalerie-js": () => import("./../../../src/pages/fotogalerie.js" /* webpackChunkName: "component---src-pages-fotogalerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informace-js": () => import("./../../../src/pages/informace.js" /* webpackChunkName: "component---src-pages-informace-js" */),
  "component---src-pages-potvrdit-ucast-js": () => import("./../../../src/pages/potvrdit-ucast.js" /* webpackChunkName: "component---src-pages-potvrdit-ucast-js" */),
  "component---src-pages-svatebni-dary-js": () => import("./../../../src/pages/svatebni-dary.js" /* webpackChunkName: "component---src-pages-svatebni-dary-js" */),
  "component---src-pages-zamluvit-darek-js": () => import("./../../../src/pages/zamluvit-darek.js" /* webpackChunkName: "component---src-pages-zamluvit-darek-js" */)
}

